<template>
  <div class="bg-white">
    <div
      class="max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5 border-t border-blue-800"
    >
      <div class="flex justify-center py-5 text-center">
        <div class="grow text-left content-center">
          ดูรายละเอียดโปรโมชั่นและเงื่อนไข<br
            class="sm:hidden"
          />ในการลงทะเบียนแลกรับสิทธิ์<br /><span
            class="font-bold text-blue-800 leading-5"
          >
            กิจกรรม ช้อปบราเดอร์ เจอ PROXIE <br class="sm:hidden" />จะสุดฟิน
            จะอินไหน เลือกเลย</span
          >
        </div>
        <div class="content-center">
          <button
            @click="goTo"
            class="rounded-2xl text-sm bg-yellow-500 text-white px-5 py-3"
          >
            ดูเพิ่มเติม
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-1 bottom-0 left-0 right-0 bg-blue-800">
    <div class="max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5 p-5">
      <div class="">
        <div class="text-md font-bold text-white mb-3">
          ติดตามโปรโมขั่นและอัพเดทข่าวสารของบราเดอร์ได้ที่
        </div>
        <div class="social-share text-center">
          <ul class="social-share-icon text-white flex">
            <li
              class="mr-2 bg-white text-blue-800 w-10 h-10 pt-1.5 inline-block rounded-full text-xl font-medium uppercase leading-normal transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <a
                href="https://www.facebook.com/BrotherCommercialThailand/"
                target="_blank"
                ><i class="bi bi-facebook"></i
              ></a>
            </li>

            <li
              class="mr-2 bg-white text-blue-800 w-10 h-10 pt-1.5 inline-block rounded-full text-xl font-medium uppercase leading-normal transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <a
                href="https://www.instagram.com/brotherthailand/"
                target="_blank"
                ><i class="bi bi-instagram"></i
              ></a>
            </li>
            <li
              class="mr-2 bg-white text-blue-800 w-10 h-10 pt-1.5 inline-block rounded-full text-xl font-medium uppercase leading-normal transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <a
                href="https://www.youtube.com/@brotherthofficial"
                target="_blank"
                ><i class="bi bi-youtube"></i
              ></a>
            </li>
            <li
              class="mr-2 bg-white text-blue-800 w-10 h-10 pt-1.5 inline-block rounded-full text-xl font-medium uppercase leading-normal transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <a href="https://www.tiktok.com/@brotherthailand" target="_blank"
                ><i class="bi bi-tiktok"></i
              ></a>
            </li>
            <li
              class="mr-2 bg-white text-blue-800 w-10 h-10 pt-1.5 inline-block rounded-full text-xl font-medium uppercase leading-normal transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <a
                href="https://twitter.com/brotherthailand
"
                target="_blank"
                ><i class="bi bi-twitter-x"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import configs from "@/config";
export default {
  data() {
    return { configs };
  },
  async created() {},
  async mounted() {},
  methods: {
    goTo() {
      window.open(configs.urlBrotherPage);
    },
  },
};
</script>
