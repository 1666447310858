<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <h1
        v-if="step < 3"
        class="text-xl w-full text-center strokeme text-blue-800 py-5"
      >
        <span class="text-gray-800 text-xl">ลงทะเบียนร่วมกิจกรรม</span
        ><br /><span class="font-extrabold">ช้อปบราเดอร์เจอ PROXIE</span>
      </h1>
      <Loading
        v-model:active="isLoading"
        :is-full-page="true"
        color="#0d2ea0"
      ></Loading>
      <div v-if="!isLoading">
        <div v-if="!userIsLogin">
          <div v-if="step === 1">
            <div
              class="bg-extra-100 rounded-t-2xl p-5 text-white text-center shadow-lg"
            >
              <div class="my-3 text-xl text-white font-extrabold">
                ฟินทุกโมเมนต์กับ PROXIE<br />(Exclusive Meet & Greet)
              </div>

              <div class="text-sm">
                ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 35,000 บาท
              </div>
              <router-link to="/names-meeting-and-greet">
                <button
                  class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
                >
                  <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
                  ตรวจสอบสิทธิ์
                </button></router-link
              >
            </div>

            <button
              @click="(step = 2), (campaign = 1)"
              class="mb-10 w-full text-lg font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black"
            >
              กิจกรรมฟินทุกโมเม้นต์กับ PROXIE <i class="bi bi-arrow-right"></i>
            </button>

            <div class="bg-extra-200 rounded-t-2xl p-5 text-white text-center">
              <div class="my-3 text-xl text-white font-extrabold">
                อินทุกแมตช์กับ PROXIE<br />(Big Sport Day)
              </div>

              <div class="text-sm">
                ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 5,000 บาท
              </div>
              <router-link to="/names-big-sport-day">
                <button
                  class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
                >
                  <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
                  ตรวจสอบสิทธิ์
                </button></router-link
              >
            </div>
            <button
              @click="(step = 2), (campaign = 2)"
              class="mb-5 w-full text-lg font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black"
            >
              กิจกรรมอินทุกแมตช์กับ PROXIE <i class="bi bi-arrow-right"></i>
            </button>
          </div>
          <div v-if="step === 2">
            <div class="mt-5">
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-white"></label>
                <input
                  v-model="registFName"
                  type="text"
                  placeholder="ชื่อ"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-white"></label>

                <input
                  v-model="registLName"
                  type="text"
                  placeholder="นามสกุล"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-white"></label>
                <input
                  v-model="registMobile"
                  type="tel"
                  placeholder="เบอร์โทร"
                  v-mask="'###-###-####'"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <input
                  v-model="registEmail"
                  type="text"
                  placeholder="อีเมล์"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <input
                  v-model="registIdCard"
                  type="text"
                  placeholder="เลขบัตรประชาชน/เลขที่หนังสือเดินทาง"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div
                class="my-5 relative flex w-full flex-wrap items-stretch mb-3"
              >
                <div class="flex">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value=""
                      v-model="registAcceptPdpa"
                      @click="showModalAccept1 = true"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >ข้าพเจ้าได้อ่านและยอมรับ</label
                    >
                    <span
                      id="helper-checkbox-text"
                      class="font-medium text-blue-800"
                    >
                      ประกาศนโยบายความเป็นส่วนตัวสำหรับกิจกรรม
                    </span>
                    แล้ว
                  </div>
                </div>
              </div>
              <div
                class="my-3 relative flex w-full flex-wrap items-stretch mb-3"
              >
                <div class="flex">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value=""
                      @click="showModalAccept2 = true"
                      v-model="registAcceptCondition"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >ข้าพเจ้าได้อ่านและยอมรับ</label
                    >
                    <span
                      id="helper-checkbox-text"
                      class="font-medium text-blue-800"
                    >
                      เงื่อนไขและกติกาการร่วมกิจกรรม
                    </span>
                    แล้ว
                  </div>
                </div>
              </div>

              <div
                class="relative flex w-full flex-wrap items-stretch mt-5 mb-3"
              >
                <button
                  @click="handleRegister"
                  class="w-full text-xl font-medium px-3 py-4 rounded-lg bg-blue-800 text-white"
                >
                  ลงทะเบียน
                </button>
              </div>
            </div>

            <div class="flex">
              <span class="">คุณลงทะเบียนแล้วหรือยัง?</span>
              <router-link to="/login">
                <span class="text-blue-800 ml-2">เข้าสู่ระบบ</span>
              </router-link>
            </div>
          </div>

          <div v-if="step === 3">
            <div
              class="bg-gray-300 rounded-2xl p-5 mb-5 text-black text-center"
            >
              <div class="font-bold text-blue-800 text-2xl">
                ลงทะเบียนร่วมรายการ<br />เรียบร้อยแล้ว
              </div>
              <div class="text-5xl text-green-400 my-8">
                <i class="bi bi-check-circle-fill" style="font-size: 80px"></i>
              </div>

              รหัสผ่านสำหรับการล็อคอิน คือ<br /><span
                class="font-bold text-blue-800"
                >เลขบัตรประชาชน / เลขที่หนังสือเดินทาง<br />
              </span>
              ({{ registIdCard }})

              <router-link to="/submit">
                <button
                  class="mt-5 w-full text-xl font-bold px-3 py-4 rounded-xl bg-blue-800 text-white"
                >
                  ส่งข้อมูลและใบกำกับภาษี
                </button></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
      <div v-if="showModalAccept1">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="bg-white z-50 pacity-0 transform relative w-11/12 md:w-1/2 rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept1 = false"
              class="absolute -top-3 -right-3 bg-red-800 hover:bg-yellow-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept1 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-800 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-bold text-blue-800 text-xl">
                {{ headerAccept1 }}
              </h2>
            </div>

            <div
              class="w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept1"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModalAccept2">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="bg-white z-50 pacity-0 transform relative w-11/12 md:w-1/2 rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept2 = false"
              class="absolute -top-3 -right-3 bg-red-800 hover:bg-yellow-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept2 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-800 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-bold text-blue-800 text-xl">
                {{ headerAccept2 }}
              </h2>
            </div>

            <div
              class="w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import router from "../router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;
  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }
    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }
  return unmaskedValue;
}
function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  console.log(re.test(email));
  return re.test(email);
}
export default {
  data() {
    return {
      step: 1,
      isLoading: true,
      campaign: 0,
      showModalAccept1: false,
      showModalAccept2: false,

      headerAccept1: null,
      headerAccept2: null,
      contentAccept1: null,
      contentAccept2: null,

      registFName: "",
      registLName: "",
      registMobile: "",
      registMobileRaw: "",
      registEmail: "",
      registIdCard: "",
      registAcceptCondition: "",
      registAcceptPdpa: "",
    };
  },
  components: {
    Loading,
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
  },
  service: null,
  created() {
    this.service = new Service();
    if (this.loggedIn) {
      window.location.replace("/submit");
    }
  },
  async mounted() {
    this.isLoading = true;

    await this.service.getContent("PDPA").then((data) => {
      if (data.contentData) {
        this.headerAccept1 = data.contentData.PC_HEADER;
        this.contentAccept1 = data.contentData.PC_BODY;
      }
    });
    await this.service.getContent("CONDITION").then((data) => {
      if (data.contentData) {
        this.headerAccept2 = data.contentData.PC_HEADER;
        this.contentAccept2 = data.contentData.PC_BODY;
      }
    });

    this.isLoading = false;
  },
  methods: {
    async handleRegister() {
      let isError = false;
      let txtError = "";
      if (this.registFName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกชื่อ</li>";
      }
      if (this.registLName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกนามสกุล</li>";
      }
      if (this.registMobile === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      } else {
        this.registMobileRaw = unmask(
          this.registMobile,
          "###-###-####"
        ).toString();

        if (this.registMobileRaw.length != 10) {
          isError = true;
          txtError += "<li>เบอร์มือถือไม่ถูกต้อง</li>";
        } else {
          await this.service
            .isMobileDuplicate(this.registMobileRaw)
            .then((data) => {
              // console.log(data.isDup);
              if (data.checkResult === "DUPLICATE") {
                //

                isError = true;
                txtError += "<li>เบอร์นี้มีการลงทะเบียนไว้แล้ว</li>";
              }
            });
        }
      }
      if (this.registEmail === "") {
        isError = true;
        txtError += "<li>กรุณากรอกอีเมล์</li>";
      } else {
        if (!validEmail(this.registEmail)) {
          isError = true;
          txtError += "<li>อีเมลไม่ถูกต้อง กรุณาตรวจสอบอีเมล</li>";
        }
      }
      if (this.registIdCard === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเลขบัตรประชาชน / เลขที่หนังสือเดินทาง</li>";
      }
      if (!this.registAcceptPdpa) {
        isError = true;
        txtError += "<li>กรุณายอมรับนโยบายการคุ้มครองข้อมูล</li>";
      }
      if (!this.registAcceptCondition) {
        isError = true;
        txtError += "<li>กรุณายอมรับกติกาการร่วมกิจกรรม</li>";
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("fname", this.registFName);
        formData.append("lname", this.registLName);
        formData.append("mobile", this.registMobileRaw);
        formData.append("email", this.registEmail);
        formData.append("idcard", this.registIdCard);

        formData.append(
          "accept",
          JSON.stringify({
            PDPA: this.registAcceptPdpa,
            CONDITION: this.registAcceptCondition,
            CAMPAIGN: this.campaign,
          })
        );
        this.service.submitRegister(formData).then((data) => {
          if (data.isSuccess === 1) {
            // do login
            let user = {
              username: this.registMobileRaw,
              password: this.registIdCard,
            };
            this.$store.dispatch("authen/login", user).then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                this.step = 3;
              }
            });

            //window.location.reload();
          } else {
            this.$swal({
              html: data.txtMessage,
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonColor: "#991b1b",

              cancelButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#991b1b",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
