<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#0d2ea0"
    ></loading>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <div class="p-3">
        <div class="bg-white">
          <div class="p-2"></div>

          <div class="px-5 pt-5 mb-1 text-center text-black">
            <span class="font-bold text-xl text-blue-800">ตรวจสอบสิทธิ์</span
            ><br />ที่จะได้รับสิทธิ์เข้าร่วมกิจกรรม *<br /><br />
            <span class="text-2xl font-bold text-yellow-500"
              >ฟินทุกโมเม้นต์ กับ PROXIE</span
            ><br />
            <span class="text-xl font-bold text-black"
              >(Exclusive Meet & Greet)</span
            >
          </div>
          <div class="text-black text-md mb-3 leading-tight">
            <div class="px-5 text-gray-500 text-sm">
              <br />
              <ul>
                <li>
                  อัพเดทสิทธิ์ วันที่ 18 ตุลาคม 2566 เวลา 17.00 น. และทุกวัน
                  เวลา 17.00 น. จนครบจำนวน
                </li>
              </ul>
            </div>
          </div>
          <div class="py-5">
            <div class="overflow-x-auto overflow-y-auto relative rounded-lg">
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
              >
                <thead>
                  <tr class="text-left text-sm bg-blue-900">
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      ลำดับที่
                    </th>
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      ชื่อ - นามสกุล
                    </th>
                    <!-- <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สาขา/ช่องทางที่ซื้อ
                </th> -->
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      เบอร์โทร
                    </th>

                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      วันที่ได้รับสิทธิ์
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm text-gray-700">
                  <tr v-for="(h, index) in winNoList" :key="index">
                    <td
                      class="text-left border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      {{ index + 1 }}.
                    </td>
                    <td
                      class="border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      {{ h.T_NAME }}
                    </td>
                    <!-- <td class="fz12 border-b border-gray-200 px-3 py-2">
                  {{ h.CB_SUBMIT_BUY_SHOP_NAME }}
                </td> -->
                    <td
                      class="border-b border-gray-200 px-2 py-1 whitespace-nowrap"
                    >
                      {{ h.T_MOBILE }}
                    </td>

                    <td
                      class="fz12 border-b border-gray-200 px-2 py-1 whitespace-nowrap text-right"
                    >
                      {{ h.T_DATETIME }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../service/service";
import router from "../router";
export default {
  name: "Off",
  props: ["state"],
  data() {
    return { isLoading: false, fullPage: true, txtMessage: "", winNoList: [] };
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.isLoading = true;
    await this.service.getTopSpender(1).then((rez) => {
      console.log(rez.topData);
      this.winNoList = rez.topData;
    });
    this.isLoading = false;
  },
};
</script>
