import { createRouter, createWebHistory } from "vue-router";

import Condition from "../views/Condition.vue";
import History from "../views/History.vue";
import WinnerVue from "@/views/Winner.vue";
import Home from "@/views/Home.vue";
import Register from "@/views/Register.vue";
import Login from "@/views/Login.vue";
import Submit from "@/views/Submit.vue";
import Success from "@/views/Success.vue";
import NamesMeetingAndGreet from "@/views/NamesMeetingAndGreet.vue";
import NamesBigSportDay from "@/views/NamesBigSportDay.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/login",
    component: Login,
  },

  {
    path: "/submit",
    component: Submit,
  },
  {
    path: "/success",
    component: Success,
  },

  {
    path: "/condition",
    component: Condition,
  },

  {
    path: "/history",
    component: History,
  },
  {
    path: "/names-meeting-and-greet",
    component: NamesMeetingAndGreet,
  },
  {
    path: "/names-big-sport-day",
    component: NamesBigSportDay,
  },

  {
    path: "/winner",
    component: WinnerVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // console.log(to.path);
  const publicPages = [
    "/",
    "/home",
    "/login",
    "/register",
    "/condition",
    "/winner",
    "/names-meeting-and-greet",
    "/names-big-sport-day",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // keep current path
  localStorage.setItem("path", to.path);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
export default router;
