<template>
  <div class="max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5">
    <div class="p-5">
      <h1 class="font-bold text-2xl w-full text-center strokeme text-blue-800">
        เข้าสู่ระบบ
      </h1>

      <div class="mt-5">
        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <label class="font-bold mb-2 text-white"></label>

          <input
            v-model="inputLoginUser"
            type="tel"
            placeholder="เบอร์มือถือ"
            class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
          />
        </div>
        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <label class="font-bold mb-2 text-white"></label>

          <input
            v-model="inputLoginPassword"
            type="password"
            placeholder="เลขบัตรประชาชน / เลขที่หนังสือเดินทาง"
            class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
          />
        </div>
        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <button
            @click="handleLogin"
            class="btn-submit w-full text-xl font-medium px-3 py-4 rounded-lg bg-blue-800 text-white"
          >
            <span>เข้าสู่ระบบ</span>
          </button>
        </div>
      </div>

      <div class="flex">
        <p class="">คุณลงทะเบียนแล้วหรือยัง?</p>
        <router-link to="/register">
          <button class="">
            <div class="text-blue-800 ml-2">ลงทะเบียน</div>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputLoginUser: "",
      inputLoginPassword: "",
    };
  },
  setup() {},
  computed: {
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },

    getReturnUrl() {
      return this.$store.state.returnUrl;
    },
  },
  created() {
    this.loading = false;
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();
      let isError = false;
      let txtError = "";
      if (this.inputLoginUser === null || this.inputLoginUser === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      }
      if (this.inputLoginPassword === null || this.inputLoginPassword === "") {
        isError = true;
        txtError += "<li>กรุณากรอกรหัสผ่าน</li>";
      }
      if (!isError) {
        this.loading = true;
        let user = {
          username: this.inputLoginUser,
          password: this.inputLoginPassword,
        };
        let returnUrl = this.getReturnUrl;

        this.$store.dispatch("authen/login", user).then(
          (data) => {
            console.log(data);
            if (data.isSuccess === 1) {
              if (!returnUrl) {
                returnUrl = "/";
              }
              // this.$router.push(returnUrl);
              window.location.replace(returnUrl);
            } else {
              txtError += "<li>ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง</li>";

              this.$swal({
                html: "<ul>" + txtError + "</ul>",
                title: "ล็อกอินผิดพลาด",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ปิดหน้าต่าง",
              });
            }
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        this.message = "กรุณากรอกข้อมูลให้ครบถ้วน";
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ปิดหน้าต่าง",
        });
      }
    },
  },
};
</script>
