<template>
  <Header />

  <div class="bg-white min-h-[60vh] pb-20"><router-view /></div>

  <Footer />
  <!-- <div v-else class="grid h-screen place-items-center">
    <img src="./assets/images/brother-logo.png" class="right-0 h-20" />
  </div> -->
</template>
<script>
import configs from "@/config";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      configs,
    };
  },
  created() {},
  methods: {},
};
</script>
<style>
#app .content li {
  display: list-item;
}
#app .content ul {
  list-style: disc;
  margin-left: 20px;
}
#app .content ol {
  list-style: decimal;
  margin-left: 20px;
}
#app .content p {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
