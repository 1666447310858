import axios from "axios";
import configs from "../config";

class AuthService {
  login(user) {
    let formData = new FormData();
    formData.append("action", configs.prefix.toLowerCase() + "/user-login");
    formData.append("username", user.username);
    formData.append("password", user.password);
    formData.append("prefix", configs.prefix);

    return axios.post(configs.urlApi, formData).then((response) => {
      let data = response.data;
      if (data.isSuccess === 1) {
        localStorage.setItem("user", user.username);
      }
      return data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }
}
export default new AuthService();
