<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <div class="p-5">
        <div class="bg-gray-300 rounded-2xl p-5 mb-5 text-black text-center">
          <div class="font-bold text-blue-800 text-2xl">
            แนบใบกำกับภาษีเต็มรูป<br />สำเร็จแล้ว
          </div>
          <div class="text-5xl text-green-400 my-8">
            <i class="bi bi-check-circle-fill" style="font-size: 80px"></i>
          </div>

          <router-link to="/submit">
            <button
              class="mt-5 w-full text-xl font-bold px-3 py-4 rounded-xl bg-blue-800 text-white"
            >
              ส่งข้อมูลและใบกำกับภาษีเพิ่มเติม
            </button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
