<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <h1 class="text-xl w-full text-center strokeme text-blue-800 py-5">
        <span class="text-gray-800 text-xl"
          >ส่งใบกำกับภาษีเต็มรูปแบบเพื่อร่วมกิจกรรม </span
        ><br /><span class="font-extrabold" v-if="step === 2 && campaign === 1"
          >ฟินทุกโมเม้นต์ กับ PROXIE </span
        ><span class="font-extrabold" v-if="step === 2 && campaign === 2"
          >อินทุกแมตช์ กับ PROXIE
        </span>
      </h1>
      <Loading
        v-model:active="isLoading"
        :is-full-page="true"
        color="#0d2ea0"
      ></Loading>
      <div v-if="!isLoading">
        <div v-if="!userIsLogin">
          <div v-if="step === 1">
            <div class="bg-extra-100 rounded-t-2xl p-5 text-white text-center">
              <div class="my-3 text-xl text-white font-extrabold">
                ฟินทุกโมเมนต์กับ PROXIE<br />(Exclusive Meet & Greet)
              </div>

              <div class="text-sm">
                ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 35,000 บาท
              </div>
              <router-link to="/names-meeting-and-greet">
                <button
                  class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
                >
                  <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
                  ตรวจสอบสิทธิ์
                </button></router-link
              >
            </div>
            <button
              @click="(step = 2), (campaign = 1)"
              class="mb-10 w-full text-lg font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black"
            >
              กิจกรรมฟินทุกโมเม้นต์กับ PROXIE <i class="bi bi-arrow-right"></i>
            </button>
            <div class="bg-extra-200 rounded-t-2xl p-5 text-white text-center">
              <div class="my-3 text-xl text-white font-extrabold">
                อินทุกแมตช์กับ PROXIE<br />(Big Sport Day)
              </div>

              <div class="text-sm">
                ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 5,000 บาท
              </div>
              <router-link to="/names-big-sport-day">
                <button
                  class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
                >
                  <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
                  ตรวจสอบสิทธิ์
                </button></router-link
              >
            </div>
            <button
              @click="(step = 2), (campaign = 2)"
              class="mb-5 w-full text-lg font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black"
            >
              กิจกรรมอินทุกแมตช์กับ PROXIE <i class="bi bi-arrow-right"></i>
            </button>
          </div>
          <div v-if="step === 2">
            <div class="mt-5">
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="mb-2 text-black"
                  ><span class="font-bold">เลขที่ใบกำกับภาษี</span
                  ><br />(กรุณาใส่เครื่องหมาย ; กรณีมีมากกว่า 1 ใบกำกับภาษี เช่น
                  1111; 2222; 3333)</label
                >
                <input
                  v-model="submitBillNo"
                  type="text"
                  placeholder="เลขที่ใบกำกับภาษี"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-black">จำนวนเงิน</label>

                <input
                  v-model="submitAmount"
                  type="number"
                  placeholder="จำนวนเงิน"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="mb-2 text-black"
                  ><span class="font-bold">ชื่อร้านที่ซื้อสินค้า</span
                  ><br />(กรุณาใส่เครื่องหมาย ; กรณีมีมากกว่า 1 ร้าน เช่น
                  ร้านค้า A; ร้านค้า B; ร้านค้า C)</label
                >
                <input
                  v-model="submitShop"
                  type="text"
                  placeholder="ชื่อร้านที่ซื้อสินค้า"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>

              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-white"></label>
                <button
                  @click="showModal = true"
                  class="border border-gray-200 py-2 px-5 rounded-3xl bg-gray-600 text-white"
                >
                  เพิ่มรายการสินค้า +
                </button>
              </div>
              <div class="pb-5">
                <div
                  class="overflow-x-auto overflow-y-auto relative rounded-lg"
                >
                  <table
                    class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
                  >
                    <thead>
                      <tr class="text-left text-sm bg-gray-100">
                        <th
                          class="text-center sticky text-black top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                        ></th>
                        <th
                          class="text-center sticky text-black top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                        >
                          หมวดหมู่สินค้า
                        </th>

                        <th
                          class="text-center sticky text-black top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                        >
                          ชื่อรุ่นสินค้า<br />(Model)
                        </th>

                        <th
                          class="text-center sticky text-black top-0 border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                        >
                          หมายเลขซีเรียล<br />(Serial Number)
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-sm text-gray-700">
                      <template v-for="(h, index) in submitItems" :key="index"
                        ><tr v-if="h.status">
                          <td
                            class="text-left border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                          >
                            <button
                              @click="itemDeleteRow(h.id)"
                              class="bg-red-800 text-white rounded-full p-1 pt-1.5 w-6 h-6 text-xs"
                            >
                              <i class="bi bi-x-lg"></i>
                            </button>
                          </td>
                          <td
                            class="border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                          >
                            {{ h.cate }}
                          </td>

                          <td
                            class="border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                          >
                            {{ h.model }}
                          </td>

                          <td
                            class="border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                          >
                            {{ h.sn }}
                          </td>
                        </tr></template
                      >
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="my-3 relative w-full flex-wrap items-stretch mb-3">
                <div class="font-bold my-3">
                  คุณรู้จักแบรนด์บราเดอร์ได้อย่างไร
                </div>
                <div class="flex my-1">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="submitQ1"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >พรีเซนเตอร์ (PROXIE)
                    </label>
                  </div>
                </div>
                <div class="flex my-1">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="submitQ2"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >สื่อโฆษณาต่างๆ
                    </label>
                  </div>
                </div>
                <div class="flex my-1">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="submitQ3"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >การแนะนำจากคนรอบข้าง
                    </label>
                  </div>
                </div>
                <div class="flex my-1">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="submitQ4"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >สื่อ ณ จุดขาย
                    </label>
                  </div>
                </div>
                <div class="flex my-1">
                  <div class="flex items-center h-5">
                    <input
                      type="checkbox"
                      value="1"
                      v-model="submitQ5"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div class="ml-4">
                    <label
                      for="helper-checkbox"
                      class="font-medium text-gray-900"
                      >อื่นๆ (โปรดระบุ)
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="relative flex w-full flex-wrap items-stretch mb-3"
                v-if="submitQ5"
              >
                <label class="font-bold mb-2 text-white"></label>
                <input
                  v-model="submitQotherText"
                  type="text"
                  placeholder="โปรดระบุช่องทางประชาสัมพันธ์"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>

              <div class="text-gray-700 font-bold my-3">
                แนบใบกำกับภาษีเต็มรูป<br />/เอกสารตามที่ระบุในเงื่อนไขการร่วมกิจกรรม
              </div>
              <div class="text-blue-800 font-bold my-3">รูป/ไฟล์ที่ 1</div>
              <div
                class="mt-1 flex justify-center border-2 border-gray-200 border rounded-md"
              >
                <div class="space-1 p-2 text-center">
                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload1"
                      class="relative cursor-pointer rounded-md font-medium"
                    >
                      <div class="w-full py-5" v-if="submitPicUrl1 == null">
                        <svg
                          class="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div v-if="submitPicUrl1 == null">
                          อัพโหลดหรือถ่ายภาพ
                        </div>
                      </div>
                      <div v-if="submitPicUrl1" class="text-center">
                        <img :src="submitPicUrl1" style="width: 100%" />
                        <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                        <div class="mt-3 text-blue-800">
                          กดที่รูปเพื่อเปลี่ยนภาพ
                        </div>
                      </div>

                      <input
                        id="file-upload1"
                        name="file-upload1"
                        type="file"
                        class="sr-only"
                        @change="onFileChange1"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="text-blue-800 font-bold my-3">รูป/ไฟล์ที่ 2</div>
              <div
                class="mt-1 flex justify-center border-2 border-gray-200 border rounded-md"
              >
                <div class="space-1 p-2 text-center">
                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload2"
                      class="relative cursor-pointer rounded-md font-medium"
                    >
                      <div class="w-full py-5" v-if="submitPicUrl2 == null">
                        <svg
                          class="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div v-if="submitPicUrl2 == null">
                          อัพโหลดหรือถ่ายภาพ
                        </div>
                      </div>
                      <div v-if="submitPicUrl2" class="text-center">
                        <img :src="submitPicUrl2" style="width: 100%" />
                        <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                        <div class="mt-3 text-blue-800">
                          กดที่รูปเพื่อเปลี่ยนภาพ
                        </div>
                      </div>

                      <input
                        id="file-upload2"
                        name="file-upload2"
                        type="file"
                        class="sr-only"
                        @change="onFileChange2"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="text-blue-800 font-bold my-3">รูป/ไฟล์ที่ 3</div>
              <div
                class="mt-1 flex justify-center border-2 border-gray-200 border rounded-md"
              >
                <div class="space-1 p-2 text-center">
                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload3"
                      class="relative cursor-pointer rounded-md font-medium"
                    >
                      <div class="w-full py-5" v-if="submitPicUrl3 == null">
                        <svg
                          class="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div v-if="submitPicUrl3 == null">
                          อัพโหลดหรือถ่ายภาพ
                        </div>
                      </div>
                      <div v-if="submitPicUrl3" class="text-center">
                        <img :src="submitPicUrl3" style="width: 100%" />
                        <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                        <div class="mt-3 text-blue-800">
                          กดที่รูปเพื่อเปลี่ยนภาพ
                        </div>
                      </div>

                      <input
                        id="file-upload3"
                        name="file-upload3"
                        type="file"
                        class="sr-only"
                        @change="onFileChange3"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div
                class="relative flex w-full flex-wrap items-stretch mt-5 mb-3"
              >
                <button
                  @click="handleSubmit"
                  class="w-full text-xl font-medium px-3 py-4 rounded-lg bg-blue-800 text-white"
                >
                  ส่งข้อมูลและใบกำกับภาษี
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
      <div v-if="showModal">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start items-center"
        >
          <div
            class="bg-white z-50 pacity-0 transform relative w-11/12 md:w-1/2 lg:w-1/3 rounded shadow-lg transition-opacity transition-transform duration-300"
          >
            <button
              @click="closeModal()"
              class="absolute -top-3 -right-3 bg-red-800 hover:bg-red-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>

            <div class="p-5 px-7">
              <h2 class="font-bold text-blue-800 text-xl">เพิ่มรายการสินค้า</h2>
            </div>

            <div class="w-full px-5 pb-10 overflow-x-hidden overflow-y-auto">
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="font-bold mb-2 text-white"></label>
                <select
                  v-model="submitCategory"
                  placeholder=""
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                >
                  <option value="">หมวดหมู่สินค้า</option>
                  <option value="ปริ้นเตอร์">ปริ้นเตอร์</option>
                  <option value="สแกนเนอร์">สแกนเนอร์</option>
                  <option value="เครื่องพิมพ์ฉลาก (เครื่องปริ้นสติกเกอร์)">
                    เครื่องพิมพ์ฉลาก (เครื่องปริ้นสติกเกอร์)
                  </option>
                  <option value="จักรเย็บผ้า">จักรเย็บผ้า</option>
                  <option value="ScanNCut">ScanNCut</option>
                  <option value="BMB">BMB</option>
                  <option :value="otherText">
                    {{ otherText }}
                  </option>
                </select>
              </div>
              <div
                class="relative flex w-full flex-wrap items-stretch mb-3"
                v-if="submitCategory !== '' && submitCategory !== otherText"
              >
                <label class="font-bold mb-2 text-white"></label>
                <input
                  v-model="submitModel"
                  type="text"
                  placeholder="ชื่อรุ่นของสินค้า"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <div
                class="relative flex w-full flex-wrap items-stretch mb-3"
                v-if="submitCategory !== '' && submitCategory !== otherText"
              >
                <label class="font-bold mb-2 text-white"></label>
                <input
                  v-model="submitSerialNo"
                  type="text"
                  placeholder="หมายเลขซีเรียล (Serial Number)"
                  class="text-lg bg-gray-100 px-5 py-3 relative border-0 w-full rounded-lg"
                />
              </div>
              <button
                @click="itemAddRow()"
                class="w-full text-xl font-medium px-3 py-4 rounded-lg bg-blue-800 text-white"
              >
                เพิ่มรายการ
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import router from "../router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";

export default {
  data() {
    return {
      step: 1,
      isLoading: false,
      fullPage: true,
      campaign: 0,
      showModal: false,
      submitBillNo: "",
      submitAmount: "",
      submitShop: "",
      submitPic1: null,
      submitPicUrl1: null,
      submitPicName1: null,
      submitPic2: null,
      submitPicUrl2: null,
      submitPicName2: null,
      submitPic3: null,
      submitPicUrl3: null,
      submitPicName3: null,
      submitCategory: "",
      submitModel: "",
      submitSerialNo: "",
      submitQ1: false,
      submitQ2: false,
      submitQ3: false,
      submitQ4: false,
      submitQ5: false,
      submitQotherText: "",

      submitItems: [],
      itemId: 1,
      otherText:
        "อื่นๆ (วัสดุการพิมพ์ : หมึก, โทนเนอร์, กระดาษโฟโต้, TZe- Tape)",
    };
  },
  components: {
    Loading,
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {},
  methods: {
    itemAddRow() {
      let isError = false;
      let txtError = "";
      if (this.submitCategory === "") {
        isError = true;
        txtError += "<li>กรุณาระบุหมวดหมู่สินค้า</li>";
      } else {
        console.log(this.submitModel, this.submitSerialNo);
        if (this.submitCategory !== this.otherText) {
          if (this.submitModel === "") {
            isError = true;
            txtError += "<li>กรุณากรอกชื่อรุ่นของสินค้า</li>";
          }
          if (this.submitSerialNo === "") {
            isError = true;
            txtError += "<li>กรุณากรอกหมายเลขซีเรียล (Serial Number)</li>";
          }
        }
      }
      if (!isError) {
        this.itemId += 1;
        this.submitItems.push({
          id: this.itemId,
          cate: this.submitCategory,
          model: this.submitModel,
          sn: this.submitSerialNo,
          status: true,
        });
        this.closeModal();
      } else {
        this.isLoading = false;
        this.btnDisable = false;
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
    itemDeleteRow(id) {
      this.submitItems.forEach((e) => {
        if (e.id === id) {
          e.status = false;
        }
      });
    },
    closeModal() {
      (this.showModal = false),
        (this.submitCategory = ""),
        (this.submitModel = ""),
        (this.submitSerialNo = "");
    },
    onFileChange1(e) {
      this.submitPic1 = e.target.files[0];
      this.submitPicName1 = e.target.files[0].name;
      this.submitPicUrl1 = URL.createObjectURL(this.submitPic1);
    },
    onFileChange2(e) {
      this.submitPic2 = e.target.files[0];
      this.submitPicName2 = e.target.files[0].name;
      this.submitPicUrl2 = URL.createObjectURL(this.submitPic2);
    },
    onFileChange3(e) {
      this.submitPic3 = e.target.files[0];
      this.submitPicName3 = e.target.files[0].name;
      this.submitPicUrl3 = URL.createObjectURL(this.submitPic3);
    },
    async handleSubmit() {
      let isError = false;
      let txtError = "";
      if (this.submitBillNo === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเลขที่ใบเสร็จ</li>";
      }
      if (this.submitAmount === "" || this.submitAmount == 0) {
        isError = true;
        txtError += "<li>กรุณากรอกจำนวนเงิน</li>";
      }
      if (this.submitShop === "") {
        isError = true;
        txtError += "<li>กรุณากรอกชื่อร้านที่ซื้อสินค้า</li>";
      }

      if (
        !this.submitQ1 &&
        !this.submitQ2 &&
        !this.submitQ3 &&
        !this.submitQ4 &&
        !this.submitQ5
      ) {
        isError = true;
        txtError += "<li>กรุณาตอบคำถามเกี่ยวกับช่องทางประชาสัมพันธ์</li>";
      }
      if (this.submitQ5 && this.submitQotherText === "") {
        isError = true;
        txtError +=
          "<li>กรุณาระบุช่องทางประชาสัมพันธ์อื่นๆ ที่ทำให้คุณรู้จักแบรนบราเดอร์</li>";
      }
      if (
        (this.submitPic1 === null || this.submitPic1 === "") &&
        (this.submitPic2 === null || this.submitPic2 === "") &&
        (this.submitPic3 === null || this.submitPic3 === "")
      ) {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดภาพ อย่างน้อย 1 รูป/ไฟล์</li>";
      }

      if (!isError) {
        this.isLoading = true;
        let isUploaded = false;
        let uploadedPic1 = "";
        let uploadedPic2 = "";
        let uploadedPic3 = "";
        if (this.submitPic1 !== null) {
          let imgFormData1 = new FormData();
          imgFormData1.append("image", this.submitPic1);
          await this.service
            .uploadBill(imgFormData1)
            .then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                isUploaded = true;
                uploadedPic1 = data.uploadResult;
              } else {
                isUploaded = false;
                isError = true;
                this.$swal({
                  html: "<ul>" + data.uploadResult + "</ul>",
                  title: "พบข้อผิดพลาด",
                  icon: "error",
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonColor: "#b91c1c",
                  cancelButtonText: "ตกลง",
                });
                this.isLoading = false;
                this.btnDisable = false;
              }
            })
            .catch((error) => console.error("s3-upload", error));
        }
        if (!isError && this.submitPic2 !== null) {
          let imgFormData2 = new FormData();
          imgFormData2.append("image", this.submitPic2);
          await this.service
            .uploadBill(imgFormData2)
            .then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                isUploaded = true;
                uploadedPic2 = data.uploadResult;
              } else {
                isError = true;
                isUploaded = false;
                this.$swal({
                  html: "<ul>" + data.uploadResult + "</ul>",
                  title: "พบข้อผิดพลาด",
                  icon: "error",
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonColor: "#b91c1c",
                  cancelButtonText: "ตกลง",
                });
                this.isLoading = false;
                this.btnDisable = false;
              }
            })
            .catch((error) => console.error("s3-upload", error));
        }
        if (!isError && this.submitPic3 !== null) {
          let imgFormData3 = new FormData();
          imgFormData3.append("image", this.submitPic3);
          await this.service
            .uploadBill(imgFormData3)
            .then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                isUploaded = true;
                uploadedPic3 = data.uploadResult;
              } else {
                isError = true;
                isUploaded = false;
                this.$swal({
                  html: "<ul>" + data.uploadResult + "</ul>",
                  title: "พบข้อผิดพลาด",
                  icon: "error",
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonColor: "#b91c1c",
                  cancelButtonText: "ตกลง",
                });
                this.isLoading = false;
                this.btnDisable = false;
              }
            })
            .catch((error) => console.error("s3-upload", error));
        }
        // submit to backend

        if (!isError && isUploaded) {
          let formData = new FormData();
          formData.append("line_id", this.currentUser);
          formData.append("bill_pic", uploadedPic1);
          formData.append("bill_amount", this.submitAmount);
          formData.append("bill_campaign", this.campaign);

          formData.append("bill_shop", this.submitShop);

          formData.append("bill_no", this.submitBillNo);
          // formData.append("bill_cate", this.submitCategory);

          formData.append(
            "bill_more_data",
            JSON.stringify({
              Q1: this.submitQ1,
              Q2: this.submitQ2,
              Q3: this.submitQ3,
              Q4: this.submitQ4,
              Q5: this.submitQ5,
              QOTHER: this.submitQotherText,
              ITEMS: this.submitItems,
              PIC1: uploadedPic1,
              PIC2: uploadedPic2,
              PIC3: uploadedPic3,
            })
          );

          this.service
            .submitBill(formData)
            .then((rez) => {
              console.log(rez.data);
              if (rez.data.isSuccess === 1) {
                router.push("/success");

                this.isLoading = false;
              } else {
                this.isLoading = false;
                this.btnDisable = false;
                this.$swal({
                  html: rez.data.msgError,
                  title: "พบข้อผิดพลาด",
                  icon: "error",
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonColor: "#b91c1c",
                  cancelButtonText: "ตกลง",
                });
              }
            })
            .catch((error) => console.error("member-submit-bill", error));
        } else {
          this.isLoading = false;
          this.btnDisable = false;
          this.$swal({
            html: "เกิดข้อผิดพลาดในการอัพโหลดไฟล์/รูปภาพ กรุณาลองใหม่อีกครั้ง",
            title: "พบข้อผิดพลาด",
            icon: "error",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: "#b91c1c",
            cancelButtonText: "ตกลง",
          });
        }
      } else {
        this.isLoading = false;
        this.btnDisable = false;
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
