<template>
  <div class="bg-blue-800 brand-logo px-5 py-5 flex justify-end">
    <router-link to="/"
      ><img src="../assets/images/brother-logo.png" class="right-0 h-20"
    /></router-link>
  </div>
  <div class="bg-gray-500 text-white py-3">
    <div
      class="flex justify-center max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5 text-center leading-5 text-xs sm:text-base md:text-md"
    >
      <router-link
        to="/history"
        class="text-white px-5 py-2 border-r border-white"
        >ตรวจสอบสถานะ<br />การส่งใบกำกับภาษี</router-link
      >
      <router-link
        to="/register"
        class="text-white px-5 py-2 border-r border-white"
        >ลงทะเบียน<br />ร่วมกิจกรรม</router-link
      >
      <router-link
        to="/winner"
        class="text-white px-5 py-2 border-r border-white"
        >ประกาศ<br />รายชื่อผู้โชคดี</router-link
      >
      <router-link
        v-if="!loggedIn"
        to="/login"
        class="text-white px-5 py-2 border0 border-white"
        ><i class="bi bi-lock"></i><br />เข้าสู่ระบบ</router-link
      >
      <router-link
        v-if="loggedIn"
        to="#"
        @click="handleLogout"
        class="text-white px-5 py-2 border0 border-white"
        ><i class="bi bi-lock"></i><br />ออกจากระบบ</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("authen/logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style>
.brand-logo img {
  width: 102px;
  height: 39px;
}
</style>
