<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#0d2ea0"
      ></loading>
      <div
        class="font-bold text-2xl w-full text-center strokeme text-blue-800 pt-10 p-5"
      >
        ประวัติการร่วมรายการ
      </div>
      <div>
        <div class="p-3">
          <div class="bg-white">
            <div class="overflow-x-auto rounded-lg overflow-y-auto relative">
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
              >
                <thead>
                  <tr class="text-left text-sm">
                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      วันที่ส่งใบเสร็จ
                    </th>

                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      กิจกรรม
                    </th>
                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      จำนวนเงิน
                    </th>

                    <th
                      class="bg-blue-900 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      สถานะ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(h, index) in custHistory" :key="index">
                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CB_SUBMIT_DATETIME.substr(0, 10) }}<br />
                      {{ h.CB_SUBMIT_DATETIME.substr(11, 15) }}<br />
                    </td>

                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      <span v-if="h.CB_TYPE === '1'"
                        >ฟินทุกโมเมนต์กับ PROXIE</span
                      >
                      <span v-if="h.CB_TYPE === '2'"
                        >อินทุกแมตช์กับ PROXIE</span
                      >
                    </td>
                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap text-right"
                    >
                      {{
                        h.CB_APPROVE_STATUS === "APPROVED"
                          ? h.CB_APPROVE_BUY_AMOUNT
                          : h.CB_SUBMIT_BUY_AMOUNT
                      }}
                    </td>

                    <td
                      class="text-sm border-b border-gray-200 px-2 py-2 whitespace-nowrap"
                    >
                      {{ h.CB_APPROVE_STATUS_TEXT }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
import router from "../router";

export default {
  name: "History",
  data() {
    return {
      header: null,
      content: null,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.service.getBillHistory(this.currentUser).then((data) => {
      this.custHistory = data.historyData;

      this.isLoading = false;
    });
  },
};
</script>
