<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#0d2ea0"
      ></loading>

      <div
        class="font-bold text-2xl w-full text-center strokeme text-blue-800 pt-5 p-5"
      >
        เงื่อนไขการร่วมกิจกรรม
      </div>
      <div class="">
        <div class="p-5">
          <div v-html="content" class=""></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "Condition",
  data() {
    return {
      header: null,
      content: null,
      isLoading: true,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  mounted() {
    this.isLoading = true;
    this.service.getContent("CONDITION").then((data) => {
      this.header = data.contentData.PC_HEADER;
      this.content = data.contentData.PC_BODY;
      this.isLoading = false;
    });
  },
};
</script>
