<template>
  <div>
    <div
      class="content max-w-7xl mx-auto px-4 focus:outline-none sm:px-3 md:px-5"
    >
      <div
        class="font-bold text-2xl w-full text-center strokeme text-blue-800 p-5"
      >
        ประกาศผล
      </div>
      <div>
        <div class="bg-extra-100 rounded-2xl p-5 text-white text-center mb-5">
          <div class="my-3 text-xl text-white font-extrabold">
            ฟินทุกโมเมนต์กับ PROXIE<br />(Exclusive Meet & Greet)
          </div>

          <div class="text-sm">
            ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 35,000 บาท
          </div>
          <router-link to="/names-meeting-and-greet">
            <button
              class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
            >
              <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
              ตรวจสอบสิทธิ์
            </button></router-link
          >
        </div>
        <!-- <button
          class="mb-10 w-full text-md font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black leading-5"
        >
          กรุณารอการประกาศผลรายชื่อผู้โชคดี<br />วันที่ 12 ม.ค. 2567
        </button> -->
        <div class="bg-extra-200 rounded-2xl p-5 text-white text-center">
          <div class="my-3 text-xl text-white font-extrabold">
            อินทุกแมตช์กับ PROXIE<br />(Big Sport Day)
          </div>

          <div class="text-sm">
            ช้อปสินค้าบราเดอร์ใดๆ ที่เข้าร่วมรายการฯ<br />ครบ 5,000 บาท
          </div>
          <router-link to="/names-big-sport-day">
            <button
              class="bg-white rounded-3xl content-center shadow-lg py-2 px-5 text-blue-800 mt-5 mx-auto cursor-pointer"
            >
              <i class="bi bi-arrow-right-circle-fill"></i>&nbsp;&nbsp;
              ตรวจสอบสิทธิ์
            </button></router-link
          >
        </div>
        <!-- <button
          class="mb-5 w-full text-md font-bold px-3 py-4 rounded-b-xl bg-extra-300 text-black leading-5"
        >
          กรุณารอการประกาศผลรายชื่อผู้โชคดี<br />วันที่ 25 ม.ค. 2567
        </button> -->
      </div>
    </div>
  </div>
</template>
